import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { Divider, Grid } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  courseDesc: {
    fontFamily: "'Sintony',sans-serif",
    textAlign:"justify",
  },
});

export default function ADBMS() {
  const classes = useStyles();

  return (
    <div className={classes.toolbar}>
      <Grid container className={classes.courseDesc}>
        <Grid item xs={4}>
          <b>Instructors</b> <br />
          Dr. Kalyani Selvarajah
          <br /> Email: kalyanis@uwindsor.ca <br /> Office: MS Teams <br />
          Office Hours: Mon 4:00pm to 6:00pm
        </Grid>
        <Grid item xs={4}>
          <b>Lectures & Lab</b> <br /> MW 8:30 am to 11:20 am <br /> @ Black
          Board virtual room
        </Grid>
        <Grid item xs={4}>
           
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Course Description
          </Typography>
          This course will introduce students to advanced topics in database
          design and information retrieval. Topics covered may include DBMS
          three-schema level architectures, data models (e.g., relational,
          object-oriented model), query languages (e.g., Oracle SQL, PL/SQL),
          file organization and indexing, transaction management, concurrency
          control, security and recovery procedures, information retrieval on
          the internet, and other advanced topics (e.g., online analytical
          processing - OLAP, data warehouses and data mining).
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Recommended Reference Books
          </Typography>
          <ul>
            <li>
              An Introduction to Database Systems (8th Edition). C.J. Date{" "}
            </li>
            <li>
              Database systems: a practical approach to design, implementation,
              and management (6th Edition). T. Connolly and C. Begg.
            </li>
            <li>
              Database System Concepts (6th Edition). A. Silberschatz, H. F.
              Korth, and S. Sudarshan{" "}
            </li>
            <li>
              Fundamentals of Database Systems. R. Elmasri & S. B. Navathe
            </li>
          </ul>
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Evaluation
          </Typography>{" "}
          The following shows the course components and their weights <br />
          <div style={{ marginTop: "15px" }} />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Activity</TableCell>
                  <TableCell align="right">Grade weight</TableCell>
                  <TableCell align="right"> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Assignments
                  </TableCell>
                  <TableCell align="right">23 %</TableCell>
                  <TableCell align="right">
                    Please see the course page in Blackboard for details
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Mid Exam
                  </TableCell>
                  <TableCell align="right">10 %</TableCell>
                  <TableCell align="right">
                    July 10th Saturday 9:00 am
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Final Exam
                  </TableCell>
                  <TableCell align="right">20 %</TableCell>
                  <TableCell align="right">TBA </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Project
                  </TableCell>
                  <TableCell align="right">30 %</TableCell>
                  <TableCell align="right">
                    Group work (please see the course site in blackboard for
                    details)
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    Labs + Participation in activities
                  </TableCell>
                  <TableCell align="right">17 % </TableCell>
                  <TableCell align="right"> </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ marginTop: "15px" }} />
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Group Project
          </Typography>{" "}
          <br />
          The goal of this project is to develop knowledge on advanced database
          technology. You can propose a project that you think is interesting
          and valuable and goes in depth on database implementation on various
          aspects within the field of databases. The project needs to be
          developed by a group of 3 students (Mandatory). Further information
          will be provided in the course page on Blackboard.
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            NOTE
          </Typography>{" "}
          <br />
          The University of Windsor allows students to take a course only twice.
          If a student fails to get the specified minimum grade in a required
          course after two attempts, the student may be asked to withdraw from
          the program. The Dean's office must grant permission to take a course
          more than twice. Students must note that such permission is granted
          only under exceptional circumstances. Any student who is taking a
          course for the second time and having difficulties with the course
          must seek counseling before the last day to withdraw voluntarily from
          a course.
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Course Schedule
          </Typography>
          <div style={{ marginTop: "15px" }} />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Weeks</TableCell>
                  <TableCell>Topics</TableCell>
                  
                  <TableCell> Important Dates </TableCell>
                  <TableCell> Submission </TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>Course Outline and Introduction</TableCell>
                  
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>2</TableCell>
                  <TableCell>
                    Advanced Topic 1: Introduction to Big Data
                  </TableCell>
                  
                  <TableCell>May 21st ADD/Drop</TableCell>
                  <TableCell>Assignment 1 : MongoDB</TableCell>
                  
                </TableRow>

                <TableRow>
                  <TableCell>3</TableCell>
                  <TableCell>Advanced Topic 2: Data Mining</TableCell>
                  
                  <TableCell> </TableCell>
                  <TableCell>Lab 1 <br />P1: Project Proposal</TableCell>
                  
                </TableRow>

                <TableRow>
                  <TableCell>4</TableCell>
                  <TableCell>Introduction to Distributed DBMS</TableCell>
                  
                  <TableCell>June 6th Final Drop</TableCell>
                  <TableCell>Assignment 2: Hadoop</TableCell>
                 
                </TableRow>

                <TableRow>
                  <TableCell>5</TableCell>
                  <TableCell>Distributed DBMS Design</TableCell>
                  
                  <TableCell></TableCell>
                  <TableCell>Lab 2 <br /> P2: Work Distribution</TableCell>
                  
                </TableRow>

                <TableRow>
                  <TableCell>6</TableCell>
                  <TableCell>Distributed DBMS Transparency</TableCell>
                  
                  <TableCell></TableCell>
                  <TableCell>Assignment 3: Spark</TableCell>
                  
                </TableRow>

                <TableRow>
                  <TableCell>7</TableCell>
                  <TableCell>Reading Week</TableCell>
                  
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>8</TableCell>
                  <TableCell>File organization and indexing</TableCell>
                  
                  <TableCell></TableCell>
                  <TableCell>Lab 3 <br />P3: Milestone I</TableCell>
                  
                </TableRow>

                <TableRow>
                  <TableCell>9</TableCell>
                  <TableCell>Transaction Management</TableCell>
                  
                  <TableCell>July 10th Sat 9:00 am</TableCell>
                  <TableCell>Lab 4 </TableCell>
                 
                </TableRow>

                <TableRow>
                  <TableCell>10</TableCell>
                  <TableCell>Concurrency Control</TableCell>
                  
                  <TableCell>July 18th VW</TableCell>
                  <TableCell> Mid exam</TableCell>
                  
                </TableRow>

                <TableRow>
                  <TableCell>11</TableCell>
                  <TableCell>Deadlock and Recovery</TableCell>
                  
                  <TableCell></TableCell>
                  <TableCell>Assignment 4: Data Visualization <br /> P4: Milestone II</TableCell>
                 
                </TableRow>

                <TableRow>
                  <TableCell>12</TableCell>
                  <TableCell>Security and Administration</TableCell>
                  
                  <TableCell></TableCell>
                  <TableCell>
                    P5: Project Presentation <br />
                    P6: Final Report (tentative)
                  </TableCell>
                  
                </TableRow>

                <TableRow>
                  <TableCell>13</TableCell>
                  <TableCell>
                    Advanced Topic 3: Data Warehouse Courses wrap up
                  </TableCell>
                  
                  <TableCell></TableCell>
                  <TableCell>Lab 5</TableCell>
                  
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Note
          </Typography>

          <Typography paragraph>
            the last seven calendar days prior to, and including, the last day
            of classes are free from any procedures for which a mark will be
            assigned.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Teaching Evaluation
          </Typography>
          Student Evaluation of Teaching (SET) forms will be administered during
          the last two weeks of the class schedule.
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Preparation for lectures
          </Typography>
          Attendance at all lectures is recommended. Due to COVID19, the classes
          will be online through BB virtual classroom or MS Teams. It will be
          synchronous and asynchronous learning. Some of the concepts covered
          are difficult and the instructor will attempt to present the concepts
          in such a way as to make them easier to understand. You will find
          recorded lectures after the lectures. Students should keep the
          instructor informed if they have any problems or difficulties with the
          course contents or others.
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Student recording of lectures
          </Typography>
          Course materials prepared by the instructor (Live/Streamed lectures,
          Lecture notes, assignments, Exams, Quizzes, Projects and, supplemental
          materials or other digital course materials) are considered by the
          University to be an instructor’s intellectual property covered by the
          Copyright Act, RSC 1985, c C-42. These materials are made available to
          you for your own study purposes and cannot be shared outside of the
          class or “published” in any way. Students who do not have the
          necessary accommodations are not permitted to record lectures in any
          format (audio, video, photograph, etc.). Posting course materials or
          any recordings you may make to other websites without the expressed
          permissions of the instructor will constitute copyright infringement
          and subject to legal action.
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Missed Test Make-up Policy
          </Typography>
          For missing a mid-exam, no make-ups will be considered. If a student
          misses the mid exam for a valid reason, the corresponding mark will be
          carried over to the final exam. Students who miss the Final Exam for a
          valid reason (as per the Senate bylaws) which is given to the
          instructor before (if possible) or within 48 hours after the exam
          date, will be allowed to take a makeup exam. The Registrar will assign
          a date and time for writing a make-up Final Examination. Missing the
          Final Exam without a valid reason will result in a score of zero.
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Late Assignment Policy
          </Typography>
          Late assignments due to a valid medical reason that has been accepted
          will be provided a deadline extension at the instructor’s discretion.
          If you are in need of a medically related extension – you must make
          arrangements in advance of the original due date/time. If not, the
          students will be penalized 5% per day, including weekends and
          holidays, for overdue assignments.
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Policy on Cheating
          </Typography>
          The instructor and teaching assistants for COMP-8157 will put a great
          deal of effort into helping students to understand and to learn the
          material in the course. However, they will not tolerate any form of
          cheating. The instructor and teaching assistants will report any
          suspicion of cheating to the Director of the School of Computer
          Science. Please read the link carefully for further information:
          <a href="http://www.uwindsor.ca/academic-integrity/">http://www.uwindsor.ca/academic-integrity/</a>. A plagiarism-detection
          software may be used for some or all of the student homework
          assignments and for your project report in this course, as the case
          may be, at the instructor’s discretion as deemed necessary. Note that
          students’ homework assignments that are submitted to such software
          become part of their database. This assists in protecting your
          intellectual property. Please read the link carefully for further
          information:<a href="http://www.uwindsor.ca/academic-integrity/306/student-resources">http://www.uwindsor.ca/academic-integrity/306/student-resources</a>
          .
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Conversion of Marks
          </Typography>
          The University of Windsor uses a 100% grading system. Therefore, the
          final grades consist of raw scores (numeric values) and all grades
          below 70% are considered failures. For more details, please check the
          University of Windsor Senate policy document: Grading and Calculation
          of Averages.
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Student Accessibility Services
          </Typography>
          Student Accessibility Services (SAS) is committed to working with the
          entire campus community in creating equal access to higher education
          for all academically qualified students who may have disabilities.
          Please read <a href="http://www.uwindsor.ca/disability/">http://www.uwindsor.ca/disability/</a> .
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Students with mental health issues
          </Typography>
          Feeling Overwhelmed? From time to time, students face obstacles that
          can affect academic performance. If you experience difficulties and
          need help, it is important to reach out to someone. For help
          addressing mental or physical health concerns on campus, contact (519)
          253-3000 and please check this site for further details:<a href="https://lawlibrary.uwindsor.ca/Presto/home/home.aspx">https://lawlibrary.uwindsor.ca/Presto/home/home.aspx</a>
          
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginTop: "15px" }} />
          <Divider />
          <div style={{ marginTop: "15px" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="h4" gutterBottom>
            Sexual Misconduct
          </Typography>
          The Sexual Misconduct Response and Prevention Office provides the
          following in relation to sexual misconduct: Support, Accommodation,
          Information, Guidance, Referrals, Resources. If you need any help,
          Dusty can be contacted at ext 4550 or dustyj@uwindsor.ca. Please also
          visit the SMRP office website at <a href="http://www.uwindsor.ca/sexual-assault">http://www.uwindsor.ca/sexual-assault</a>
        </Grid>
      </Grid>
    </div>
  );
}
