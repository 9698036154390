import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import MenuBookTwoTone from "@material-ui/icons/MenuBookTwoTone";
import Link from "@material-ui/core/Link"
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginTop: "2px",
    margin: "auto",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  reference: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 8,
  },
});

export default function Publication(props) {
  const classes = useStyles();


  return (
    <Card className={classes.root}>
      
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar >
              <MenuBookTwoTone />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={props.pub.Text}
              secondary={props.pub.Citation}
            />
            <Link href={props.pub.Link} >[PDF]</Link>
          </ListItem>
        </List>
{/* 
        <Typography className={classes.reference}>
          <div style={{ float: "left" }}>
            <i className="fa fa-book" style={{ color: "#ff9800" }}></i> &nbsp;
          </div>
          <div style={{ float: "left", marginLeft: "30px" }}>
            &nbsp; &nbsp; <br />
          </div>
        </Typography> */}
      
    </Card>
  );
}
