import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Collapse from "@material-ui/core/Collapse";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItemText from "@material-ui/core/ListItemText";
import Profile from "./Profile";
import Publication from "./Publication";
import Container from "@material-ui/core/Container";
import Project from "./Project";
import Grow from "@material-ui/core/Grow";
import Grid from "@material-ui/core/Grid";
import ADBMS from "./ADBMS";
import ADBMS_WINTER from "./ADBMS_WINTER";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import BookTwoTone from "@material-ui/icons/BookTwoTone";
import HomeTwoTone from "@material-ui/icons/HomeTwoTone";

import LibraryBooksTwoTone from "@material-ui/icons/LibraryBooksTwoTone";
import ChromeReaderModeTwoTone from "@material-ui/icons/ChromeReaderModeTwoTone";

// import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';

import publication_data from "../data/publications.json";
import { Link } from "@material-ui/core";
import InternProject from "./InternProject";
import AI from "./AI";

const drawerWidth = 280;

const myStyles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    background: "#005595",
    textAlign: "right",
  },
  textStyle: {
    fontFamily: "'Sintony',sans-serif",
    textAlign: "justify",
  },
  menuButton: {
    maxWidth: "70px",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
});

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      publications: publication_data["Publications"],
      open: true,
      mobileOpen: false,
    };
  }

  handleListItemClick(event, index) {
    this.setState({
      selectedIndex: index,
      mobileOpen: false
    });
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    // console.log(this.state.publications);
  }

  handleOpenCourse(event, openCourse) {
    this.setState({
      open: !openCourse,
    });
  }

  handleDrawerToggle(event, mobileOpen) {
    this.setState({
      mobileOpen: !mobileOpen,
    });
    // console.log(mobileOpen)
  }

  render() {
    let { selectedIndex, publications, open, mobileOpen } = this.state;
    const { classes } = this.props;

    // console.log(classes.appBar);
    return (
      <div className={classes.root}>
        <CssBaseline />
        {/* Desktop main bar */}
        <Hidden smDown implementation="css">
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" noWrap className={classes.appBar}>
                University of Windsor
              </Typography>
            </Toolbar>
          </AppBar>
        </Hidden>

        {/* Mobile main bar */}
        <Hidden mdUp implementation="css">
          <AppBar position="fixed" style={{ background: "#005595" }}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={(event) => this.handleDrawerToggle(event, mobileOpen)}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                Dr. Kalyani Selvarajah
              </Typography>
            </Toolbar>
          </AppBar>
        </Hidden>

        <Hidden smUp implementation="css">
          <Drawer
            className={classes.drawer}
            variant="temporary"
            open={mobileOpen}
            onClose={(event) => this.handleDrawerToggle(event, mobileOpen)}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            anchor="left"
          >
            <Profile />
            {/* <div className={classes.toolbar} /> */}
            <Divider />
            <List>
              <ListItem
                button
                key={0}
                selected={selectedIndex === 0}
                onClick={(event) => this.handleListItemClick(event, 0)}
              >
                <ListItemIcon>
                  <HomeTwoTone />
                </ListItemIcon>
                <ListItemText primary={"Home"} />
              </ListItem>

              <ListItem
                button
                key={1}
                selected={selectedIndex === 1}
                onClick={(event) => this.handleListItemClick(event, 1)}
              >
                <ListItemIcon>
                  <BookTwoTone />
                </ListItemIcon>
                <ListItemText primary={"Publications"} />
              </ListItem>

              {/* <ListItem
              button
              key={2}
              selected={selectedIndex === 2}
              onClick={(event) => this.handleListItemClick(event, 2)}
            >
              <ListItemIcon>
                <AccountTreeTwoTone />
              </ListItemIcon>
              <ListItemText primary={"Student's Projects"} />
            </ListItem> */}

              <ListItem
                button
                key={13}
                onClick={(event) => this.handleOpenCourse(event, open)}
              >
                <ListItemIcon>
                  <LibraryBooksTwoTone />
                </ListItemIcon>
                <ListItemText primary="Courses" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    key={1}
                    selected={selectedIndex === 3}
                    onClick={(event) => this.handleListItemClick(event, 3)}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <ChromeReaderModeTwoTone />
                    </ListItemIcon>
                    <ListItemText primary={"S21 COMP 8157"} />
                  </ListItem>
                  <ListItem
                    button
                    key={2}
                    selected={selectedIndex === 4}
                    onClick={(event) => this.handleListItemClick(event, 4)}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <ChromeReaderModeTwoTone />
                    </ListItemIcon>
                    <ListItemText primary={"S21 COMP 8967"} />
                  </ListItem>
                  <ListItem
                    button
                    key={3}
                    selected={selectedIndex === 5}
                    onClick={(event) => this.handleListItemClick(event, 5)}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <ChromeReaderModeTwoTone />
                    </ListItemIcon>
                    <ListItemText primary={"W21 COMP 3710"} />
                  </ListItem>

                  <ListItem
                    button
                    key={5}
                    selected={selectedIndex === 6}
                    onClick={(event) => this.handleListItemClick(event, 6)}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <ChromeReaderModeTwoTone />
                    </ListItemIcon>
                    <ListItemText primary={"W22 COMP 8157"} />
                  </ListItem>
                </List>
              </Collapse>
            </List>
            {/* <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? "inbox" : "<MailIcon />"}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
          </Drawer>
        </Hidden>

        <Hidden smDown implementation="css">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <Profile />
            {/* <div className={classes.toolbar} /> */}
            <Divider />
            <List>
              <ListItem
                button
                key={0}
                selected={selectedIndex === 0}
                onClick={(event) => this.handleListItemClick(event, 0)}
              >
                <ListItemIcon>
                  <HomeTwoTone />
                </ListItemIcon>
                <ListItemText primary={"Home"} />
              </ListItem>

              <ListItem
                button
                key={1}
                selected={selectedIndex === 1}
                onClick={(event) => this.handleListItemClick(event, 1)}
              >
                <ListItemIcon>
                  <BookTwoTone />
                </ListItemIcon>
                <ListItemText primary={"Publications"} />
              </ListItem>

              {/* <ListItem
              button
              key={2}
              selected={selectedIndex === 2}
              onClick={(event) => this.handleListItemClick(event, 2)}
            >
              <ListItemIcon>
                <AccountTreeTwoTone />
              </ListItemIcon>
              <ListItemText primary={"Student's Projects"} />
            </ListItem> */}

              <ListItem
                button
                onClick={(event) => this.handleOpenCourse(event, open)}
              >
                <ListItemIcon>
                  <LibraryBooksTwoTone />
                </ListItemIcon>
                <ListItemText primary="Courses" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    key={1}
                    selected={selectedIndex === 3}
                    onClick={(event) => this.handleListItemClick(event, 3)}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <ChromeReaderModeTwoTone />
                    </ListItemIcon>
                    <ListItemText primary={"S21 COMP 8157"} />
                  </ListItem>
                  <ListItem
                    button
                    key={2}
                    selected={selectedIndex === 4}
                    onClick={(event) => this.handleListItemClick(event, 4)}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <ChromeReaderModeTwoTone />
                    </ListItemIcon>
                    <ListItemText primary={"S21 COMP 8967"} />
                  </ListItem>
                  <ListItem
                    button
                    key={3}
                    selected={selectedIndex === 5}
                    onClick={(event) => this.handleListItemClick(event, 5)}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <ChromeReaderModeTwoTone />
                    </ListItemIcon>
                    <ListItemText primary={"W21 COMP 3710"} />
                  </ListItem>

                  <ListItem
                    button
                    key={6}
                    selected={selectedIndex === 6}
                    onClick={(event) => this.handleListItemClick(event, 6)}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <ChromeReaderModeTwoTone />
                    </ListItemIcon>
                    <ListItemText primary={"W22 COMP 8157"} />
                  </ListItem>
                </List>
              </Collapse>
            </List>
            {/* <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? "inbox" : "<MailIcon />"}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
          </Drawer>
        </Hidden>
        <Container maxWidth="lg">
          <main className={classes.content}>
            <Grid container spacing={3}>
              <Grid item sm={10} xs={12}>
                <Grow in={selectedIndex === 1}>
                  <div>
                    {selectedIndex === 1 ? (
                      <div>
                        <div>
                          <div className={classes.toolbar} />
                          <Typography variant="h4" component="h4" gutterBottom>
                            Publications
                          </Typography>

                          <Grid container>
                            {publications.map((items, index) => (
                              <Grid key={index} md={12}>
                                <Publication pub={items} />
                              </Grid>
                            ))}
                          </Grid>
                        </div>

                        {/* <div>
                      <div className={classes.toolbar} />
                      <Typography variant="h4" component="h4" gutterBottom>
                        2020
                      </Typography>
                      <Publication /> <Publication /> <Publication />{" "}
                      <Publication />
                    </div>

                    <div>
                      <div className={classes.toolbar} />
                      <Typography variant="h4" component="h4" gutterBottom>
                        2019
                      </Typography>
                      <Publication /> <Publication /> <Publication />{" "}
                      <Publication />
                    </div> */}
                      </div>
                    ) : (
                      " "
                    )}
                  </div>
                </Grow>

                <Grow in={selectedIndex === 0}>
                  <div>
                    <div>
                      {selectedIndex === 0 ? (
                        <div>
                          <div className={classes.toolbar} />

                          <Typography variant="h4" component="h4" gutterBottom>
                            About Me
                          </Typography>
                          <Typography paragraph className={classes.textStyle}>
                            I am an assistant professor of Computer Science at
                            the University of Windsor. I received Ph.D. from the
                            University of Windsor in computer Science under the
                            supervision of Dr. Ziad Kobti and Dr. Mehdi Kargar.
                            Much of my research has been on improving the
                            understanding, design, and performance of social
                            network analysis, mainly through evolutionary
                            algorithms and deep learning techniques.
                            <br />
                            <br />
                            In my Ph.D. thesis, I investigated the promising
                            challenges with team formation problems and
                            prediction problems in social networks. In addition,
                            I have published several peer-reviewed journals and
                            conference articles, including IJCNN, ECAI, ICCS,
                            and Expert Systems with Applications. I also have
                            collaborated actively with researchers in other
                            disciplines, particularly the nursing department,
                            and mentored M.Sc. thesis students, MAC, and
                            undergraduate students. Currently, my research
                            focuses on knowledge graph completion and extends to
                            machine learning and data mining for large
                            interconnected systems.
                            <br />
                            <br />I received B.Sc. and M.Sc. in computer science
                            degrees from the University of Peradeniya, Sri
                            Lanka.
                          </Typography>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>
                  </div>
                </Grow>

                <Grow in={selectedIndex === 2}>
                  <div>
                    {selectedIndex === 2 ? (
                      <div>
                        <div>
                          <div className={classes.toolbar} />

                          <Typography variant="h4" component="h4" gutterBottom>
                            Artificial Intelligence
                          </Typography>

                          <Grid container spacing={2}>
                            <Grid item md={3}>
                              <Project />
                            </Grid>
                            <Grid item md={3}>
                              <Project />
                            </Grid>
                            <Grid item md={3}>
                              <Project />
                            </Grid>
                            <Grid item md={3}>
                              <Project />
                            </Grid>
                          </Grid>
                        </div>

                        <div>
                          <div className={classes.toolbar} />

                          <Typography variant="h4" component="h4" gutterBottom>
                            DBMS
                          </Typography>

                          <Grid container spacing={2}>
                            <Grid item md={3}>
                              <Project />
                            </Grid>
                            <Grid item md={3}>
                              <Project />
                            </Grid>
                            <Grid item md={3}>
                              <Project />
                            </Grid>
                            <Grid item md={3}>
                              <Project />
                            </Grid>
                          </Grid>
                        </div>

                        <div>
                          <div className={classes.toolbar} />

                          <Typography variant="h4" component="h4" gutterBottom>
                            Project Management
                          </Typography>

                          <Grid container spacing={2}>
                            <Grid item md={3}>
                              <Project />
                            </Grid>
                            <Grid item md={3}>
                              <Project />
                            </Grid>
                            <Grid item md={3}>
                              <Project />
                            </Grid>
                            <Grid item md={3}>
                              <Project />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    ) : (
                      " "
                    )}
                  </div>
                </Grow>

                <Grow in={selectedIndex === 3}>
                  <div>
                    {selectedIndex === 3 ? (
                      <div>
                        <div>
                          <div className={classes.toolbar} />
                          <Typography variant="h4" component="h4" gutterBottom>
                            COMP 8157- 01/02 : Advanced Database Topics
                          </Typography>

                          <Typography
                            variant="h6"
                            component="h6"
                            color="textSecondary"
                            gutterBottom
                          >
                            2021 Summer, School of Computer Science
                          </Typography>
                          <br />
                          <ADBMS />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Grow>
                

                <Grow in={selectedIndex === 4}>
                  <div>
                    {selectedIndex === 4 ? (
                      <div>
                        <div>
                          <div className={classes.toolbar} />
                          <Typography variant="h4" component="h4" gutterBottom>
                            COMP-8967-91 Internship/Project I (Online)
                          </Typography>

                          <Typography
                            variant="h6"
                            component="h6"
                            color="textSecondary"
                            gutterBottom
                          >
                            2021 Winter, School of Computer Science
                          </Typography>
                          <br />
                          <InternProject />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Grow>

                <Grow in={selectedIndex === 5}>
                  <div>
                    {selectedIndex === 5 ? (
                      <div>
                        <div>
                          <div className={classes.toolbar} />
                          <Typography variant="h4" component="h4" gutterBottom>
                            COMP 3710- 01 : Artificial Intelligence (Online)
                          </Typography>

                          <Typography
                            variant="h6"
                            component="h6"
                            color="textSecondary"
                            gutterBottom
                          >
                            2021 Winter, School of Computer Science
                          </Typography>
                          <br />
                          <AI />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Grow>

                <Grow in={selectedIndex === 6}>
                  <div>
                    {selectedIndex === 6 ? (
                      <div>
                        <div>
                          <div className={classes.toolbar} />
                          <Typography variant="h4" component="h4" gutterBottom>
                            COMP 8157- 01/02 : Advanced Database Topics
                          </Typography>

                          <Typography
                            variant="h6"
                            component="h6"
                            color="textSecondary"
                            gutterBottom
                          >
                            2022 Winter, School of Computer Science
                          </Typography>
                          <br />
                          <ADBMS_WINTER />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Grow>


              </Grid>
              <Hidden xsDown implementation="css">
                <Grid item sm={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={2} />
                    <Grid item xs={10}>
                      <Grid
                        container
                        style={{
                          position: "fixed",
                          right: "10",
                          maxWidth: "180px",
                        }}
                      >
                        <Grid item xs={12}>
                          <div style={{ marginTop: "80px" }} />
                          <Typography>
                            <b>Useful Links</b>
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <List>
                            <ListItem>
                              <Link href="https://blackboard.uwindsor.ca">
                                Blackboard
                              </Link>
                            </ListItem>

                            <Divider />

                            <ListItem>
                              <Link href="https://www.uwindsor.ca/science/computerscience/">
                                uWin School of CS
                              </Link>
                            </ListItem>

                            <Divider />

                            <ListItem>
                              <Link href="https://www.uwindsor.ca/academic-integrity/">
                                Academic Integrity
                              </Link>
                            </ListItem>

                            <Divider />

                            <ListItem>
                              <Link href="https://www.uwindsor.ca/studentaccessibility/">
                                Student Accessibility
                              </Link>
                            </ListItem>

                            <ListItem></ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </main>
        </Container>
      </div>
    );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(myStyles)(Main);
