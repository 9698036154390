import React from "react";
import "./styles/Profile.css";
import profilePicture from "./../data/images/Myphoto_web.jpg";
import { Grid } from "@material-ui/core";

const Profile = () => (
  <div className="profile-card">
    <img
      src={profilePicture}
      alt="John"
      style={{ width: "180px", height: "180px", borderRadius: "100%" }}
    />
    <h4 style={{ marginTop: "15px" }}>Dr. Kalyani Selvarajah</h4>
    <p className="profile-title">Assistant Professor</p>
    <p>University of Windsor</p>
    <div style={{ margin: "24px 0" }}>
      <Grid container style={{ maxWidth: "120px", margin: "auto" }}>
        {/* <Grid item xs={4}>
          <a className="profile-card-a" href="https://www.researchgate.net/profile/Kalyani-Selvarajah">
            <i className="fa fa-list-alt"></i>
          </a>
        </Grid> */}
        <Grid item xs={6}>
          <a className="profile-card-a" href="https://ca.linkedin.com/in/kalyanis28">
            <i className="fa fa-linkedin" />
          </a>
        </Grid>
        <Grid item xs={6}>
          <a className="profile-card-a" href="https://scholar.google.ca/citations?user=dFzn0scAAAAJ&hl=en">
            <i className="fa fa-google" />
          </a>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default Profile;
